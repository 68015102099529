import React from 'react';
import { Typography, Row, Col, Card, Button, Space, Divider, Breadcrumb } from 'antd';
import { UserOutlined, BulbOutlined, SoundOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import './About.css';

const { Title, Paragraph } = Typography;

const AboutPage = () => {
  return (
    <>
      <Helmet>
        <title>About Us | TN Industrial Connect</title>
        <meta name="description" content="Learn more about TN Industrial Connect, your gateway to connecting with manufacturers, suppliers, and service providers across Tamil Nadu. We aim to strengthen the industrial ecosystem by fostering growth and collaboration." />
        <meta name="keywords" content="About TN Industrial Connect, Industrial networking in Tamil Nadu, Business connections TN, Industrial collaboration platform" />
      </Helmet>
      <Breadcrumb className="tic-breadcrumb">
        <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to="/about">About Us</Link></Breadcrumb.Item>
      </Breadcrumb>
      <div className="tic-content-wrapper">
        <Title level={1} className="tic-about-heading-title">
          About Us
        </Title>
        <Paragraph className="tic-about-heading-desc">
          TN Industrial Connect is dedicated to fostering connections within Tamil Nadu&apos;s industrial sector. 
          Our platform connects manufacturers, suppliers, and service providers, creating opportunities for collaboration and growth.
        </Paragraph>
        <Row gutter={[24, 24]}>
          <Col xs={24} md={12}>
            <Card bordered={false}>
              <Title level={4} className="tic-about-subheading-title">
                Who are we
              </Title>
              <Paragraph  className="tic-about-subheading-desc">
                We are a networking hub for the industrial community, making it easier for businesses to find partners, clients, and suppliers. 
                Our goal is to help industries in Tamil Nadu thrive by offering a seamless platform for connection and collaboration.
              </Paragraph>
            </Card>
          </Col>
          <Col xs={24} md={12}>
            <Card bordered={false}>
              <Title level={4} className="tic-about-subheading-title">
                Our Mission
              </Title>
              <Paragraph  className="tic-about-subheading-desc">
                To strengthen Tamil Nadu&apos;s industrial ecosystem by providing a platform that facilitates meaningful connections and drives growth.
              </Paragraph>
            </Card>
          </Col>
        </Row>
        <Divider style={{ marginTop: 30, marginBottom: 60 }} />
        <Title level={2} className="tic-about-heading-title" style={{ paddingBottom: 30 }}>
          What We Do
        </Title>
        <Row gutter={[24, 24]}>
          {[
            { icon: <UserOutlined />, title: "Discover Opportunities", description: "Connect with the right partners to grow your business." },
            { icon: <BulbOutlined />, title: "Build Networks", description: "Engage with industry peers and leaders." },
            { icon: <SoundOutlined />, title: "Promote Your Business", description: "Showcase your offerings to a wider audience." },
          ].map((item, index) => (
            <Col xs={24} md={8} key={index}>
              <Card bodyStyle={{ padding: '50px 20px'}}>
                <Space direction="vertical" align="center" style={{ width: '100%' }}>
                  {React.cloneElement(item.icon, { style: { fontSize: '24px', color: '#15355e' } })}
                  <Title level={4}>{item.title}</Title>
                  <Paragraph style={{ textAlign: 'center' }}>{item.description}</Paragraph>
                </Space>
              </Card>
            </Col>
          ))}
        </Row>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Card style={{ background: '#15355e', color: 'white', marginTop: '40px', textAlign: 'center', maxWidth: 900 }} bodyStyle={{ padding: '50px 40px'}}>
            <Title level={2} style={{ color: 'white' }} className="tic-about-subheading-title">Join Us</Title>
            <Paragraph style={{ color: 'white' }} className="tic-about-subheading-desc">
              Whether you&apos;re a large manufacturer or a small business, TN Industrial Connect helps you unlock new opportunities and build lasting relationships.
            </Paragraph>
            <a href="/contact">
              <Button type="primary" size="large" style={{ marginTop: 30 }}>
                Get in Touch with Us
              </Button>
            </a>
          </Card>
        </div>
      </div>
    </>
  );
};

export default AboutPage;