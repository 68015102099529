import { Breadcrumb, Card, Col, Collapse, Empty, Pagination, Row, Space, Typography, message } from 'antd';
import axios from 'axios';
import FilterCard from 'components/Cards/FilterCard/FilterCard';
import SearchBar from 'components/Cards/SearchBar/SearchBar';
import { PRODUCT_URL, CATEGORY_URL, COMMON_URL, SUB_CATEGORY_URL } from 'constants/ApiConstants';
import React, { useEffect, useState } from 'react';
import './Search.css';
import { getBreakPoint } from 'utils/layout';
import ProductListingCard from 'components/Cards/ProductListingCard/ProductListingCard';
import { isEmpty, map, sortBy } from 'lodash';
import { Link } from 'react-router-dom';
import { getURLString } from 'utils/case-converter';

const SearchListingComponent = (props) => {
  const type = props.type;
  const searchText = props?.searchText;
  const isMobile = !getBreakPoint().includes('md');
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(9);
  const [cities, setCities] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [city, setCity] = useState(null);
  const [category, setCategory] = useState(null);
  const [subCategory, subSubCategory] = useState(null);
  const [count, setCount] = useState(0);
  const [filterRefreshing, setFilterRefreshing] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    setCity(props?.city);
    setCategory(type === "search"? props?.category : map(props?.category, '_id'));
    subSubCategory(type === "search"? props?.subCategory : map(props?.subCategory, '_id'));
  }, [props]);

  useEffect(() => {
    onLoadFilters();
  }, []);

  useEffect(() => {
    const city = props?.city;
    const category  = type === "search"? props?.category : map(props?.category, '_id');
    const subCategory = (type === "search"? props?.subCategory : map(props?.subCategory, '_id'));
    onLoadProduct(city, category, subCategory);
  }, [props.category, props.city, props.subCategory]);

  useEffect(() => {
    onLoadProduct(city, category, subCategory);
  }, [page, limit]);

  useEffect(() => {
    if (categories.length == 0 || subCategories.length === 0) {
      return;
    }
    const location = city.length > 0 ? city[0] : null;
    const catId = category.length > 0 ? category[0] : null;
    const subCatId = subCategory.length > 0 ? subCategory[0] : null;
    let url = '';
    if (location) {
      url = `/listing/${getURLString(location)}`;
    } else if (catId) {
      url = `/category`
    }
    if (catId && subCatId) {
      const cat = categories.find(c => c._id === catId);
      const subCat = subCategories.find(c => c._id === subCatId);
      url = `${url}/${cat?.slug}/${subCat?.slug}`;
    } else if (catId) {
      const cat = categories.find(c => c._id === catId);
      url = `${url}/${cat?.slug}`;
    }
    console.log(window.location.pathname, url);
    if (isEmpty(url)) {
      url = '/search';
    }
    if (window.location.pathname !== url) {
      window.location.href = url;
    }
  }, [city, category, subCategory, categories, subCategories]);

  const onLoadFilters = async () => {
    setFilterRefreshing(true);
    try {
      const res = await axios.get(`${CATEGORY_URL}?limit=500`);
      const subCatRes = await axios.get(`${SUB_CATEGORY_URL}?limit=2000`);
      const cityRes = await axios.get(`${COMMON_URL}/business/cities`);
      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else if (cityRes.data.status === 'error') {
        message.error(cityRes.data.error);
      } else if (subCatRes.data.status === 'error') {
        message.error(subCatRes.data.error);
      } else {
        setCategories(res.data.categories);
        setSubCategories(subCatRes.data.subcategories);
        setCities(sortBy(cityRes.data.cities, 'name'));
      }
    } catch (error) {
      message.error(error.message);
    }
    setFilterRefreshing(false);
  };

  const onLoadProduct = async (city, category, subCategory ) => {
    setRefreshing(true);
    try {
      const xApiFilter = {};
      if (searchText && searchText.length > 0) {
        xApiFilter.search = [searchText];
      }
      if (city && city.length > 0) {
        xApiFilter.city = city;
      }
      if (category && category.length > 0) {
        xApiFilter.category = category;
      }
      if (subCategory && subCategory.length > 0) {
        xApiFilter.subCategories = subCategory;
      }
      const res = await axios.get(`${PRODUCT_URL}?page=${page}&limit=${limit}&populate=true`, {
        headers: {
          'X-Api-Filters': JSON.stringify(xApiFilter),
        }
      });

      if (res.data.status === 'error') {
        message.error(res.data.error);
      } else {
        setProducts(res.data.products);
        setCount(res.data.count);
      }
    } catch (error) {
      message.error(error.message);
    }
    setRefreshing(false);
  };

  const showing = ((page - 1) * limit) + (products?.length || 0);

  const MobileFriendlyFilter = ({children}) => {
    if (isMobile) {
      return <Collapse><Collapse.Panel key="filter" header="Filter By" style={{ width: '100%' }}>{children}</Collapse.Panel></Collapse>
    }
    return children;
  }

  const getDynamicBreadcrumbItems = () => {
    if (type === 'location-sub-category') {
      const city = props.city.length > 0 ? props.city[0] : null;
      const cat = props.category.length > 0 ? props.category[0] : null;
      const subCat = props.subCategory.length > 0 ? props.subCategory[0] : null;
      return (
        <>
        <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to="/cities">Cities</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to={`/listing/${getURLString(city)}`}>{city}</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to={`/listing/${getURLString(city)}/${cat?.slug}`}>{cat?.name}</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to={`/listing/${getURLString(city)}/${cat?.slug}/${subCat?.slug}`}>{subCat?.name}</Link></Breadcrumb.Item>
        </>
      )
    } else if (type === 'location-category') {
      const city = props.city.length > 0 ? props.city[0] : null;
      const cat = props.category.length > 0 ? props.category[0] : null;
      return (
        <>
        <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to="/cities">Cities</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to={`/listing/${getURLString(city)}`}>{city}</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to={`/listing/${getURLString(city)}/${cat?.slug}`}>{cat?.name}</Link></Breadcrumb.Item>
        </>
      )
    } else if (type === 'sub-category') {
      const cat = props.category.length > 0 ? props.category[0] : null;
      const subCat = props.subCategory.length > 0 ? props.subCategory[0] : null;
      return (
        <>
          <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
          <Breadcrumb.Item><Link to="/categories">Categories</Link></Breadcrumb.Item>
          <Breadcrumb.Item><Link to={`/category/${cat?.slug}`}>{cat?.name}</Link></Breadcrumb.Item>
          <Breadcrumb.Item><Link to={`/category/${cat?.slug}/${subCat?.slug}`}>{subCat?.name}</Link></Breadcrumb.Item>
        </>
      )
    } else if (type === 'category') {
      const cat = props.category.length > 0 ? props.category[0] : null;
      return (
        <>
          <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
          <Breadcrumb.Item><Link to="/categories">Categories</Link></Breadcrumb.Item>
          <Breadcrumb.Item><Link to={`/category/${cat?.slug}`}>{cat?.name}</Link></Breadcrumb.Item>
        </>
      )
    } else if (type === 'location') {
      const city = props.city.length > 0 ? props.city[0] : null;
      return (
        <>
          <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
          <Breadcrumb.Item><Link to="/cities">Cities</Link></Breadcrumb.Item>
          <Breadcrumb.Item><Link to={`/listing/${getURLString(city)}`}>{city}</Link></Breadcrumb.Item>
        </>
      )
    }
    return (
      <>
        <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item><Link to="/search">Search</Link></Breadcrumb.Item>
      </>
    )
  };

  return (
    <>
      <Breadcrumb className="tic-breadcrumb">
        {getDynamicBreadcrumbItems()}
      </Breadcrumb>
      <div className='tic-content-wrapper'>
        <Row gutter={[48, 0]}>
            <Col xl={6} lg={8} sm={24} xs={24}>
              <MobileFriendlyFilter>
                <Card bordered={false} loading={filterRefreshing} bodyStyle={{ padding: 0 }}>
                  <FilterCard
                    title="Filter by Location"
                    searchPlaceholder="Search Cities"
                    value={city}
                    onChange={(city) => setCity(city)}
                    options={cities?.map(city => ({ label: city?.name, value: city?.name }))}
                  />
                  <FilterCard
                    title="Filter by Category"
                    searchPlaceholder="Search Categories"
                    className="mt-3"
                    value={category}
                    onChange={(category) => { setCategory(category); subSubCategory([]); }}
                    options={categories?.map(category => ({ label: category?.name, value: category?._id }))}
                  />
                </Card>
              </MobileFriendlyFilter>
            </Col>
            <Col xl={18} md={16} sm={24}>
              <div className="mt-3">
                <SearchBar city={city} category={category} defaultValue={searchText} />
              </div>
              <Card bordered={false} loading={refreshing} bodyStyle={{ padding: '20px 0' }}>
                <Space>
                  <Typography.Paragraph className='tic-search-result-text'>
                    Showing {showing ?? 0} out of {count ?? 0} result {searchText ? `for "${searchText}"` : ''} {city?.length > 0 ? `in "${city.join(', ')}"` : ''}
                  </Typography.Paragraph>
                </Space>
                {
                  products?.length === 0 && (
                    <Empty description="Oops, unable to find any products for the given query" />
                  )
                }
                <Row gutter={[24, 24]} style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                  {
                    products?.map((product) => (
                      <Col xl={8} lg={12} sm={24}>
                        <ProductListingCard listing={product} />
                      </Col>
                    ))
                  }
                </Row>
                {
                  products?.length > 0 && (
                    <Pagination
                      className="mt-5"
                      current={page}
                      total={count}
                      pageSize={limit}
                      onChange={(page, pageSize) => {
                        setPage(page);
                        setLimit(pageSize);
                      }}
                      responsive
                    />
                  )
                }
              </Card>
            </Col>
        </Row>
      </div>
    </>
  );
};

export default SearchListingComponent;
